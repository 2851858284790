.swiper-slide {
  height: auto !important;
  cursor: grab;
  padding-bottom: 40px;
  padding-top: 16px;
}
.swiper-slide:active {
  cursor: grabbing;
}
.swiper-container {
  --swiper-theme-color: var(--primary-color);
}

.swiper {
  z-index: 10;
  padding-left: 60px;
  padding-right: 60px;
}

.swiper-button-prev {
  left: -10px;
}
.swiper-button-next {
  right: -10px;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--secondary-color);
  height: 50px;
  width: 50px;
}

@media (max-width: 600px) {
  .swiper {
    z-index: 10;
    padding-left: 20px;
    padding-right: 20px;
  }
}
