.navigation {
  position: fixed;
  height: 70px;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease;
}
.navigation-bg {
  background-color: #fff;
  box-shadow: 0px 1px 3px #14143d;
}

.nav-pos-desktop {
  max-width: 2000px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  right: 20;
  position: absolute;
}

.nav-pos-mobile-hamburger {
  /* display: flex; */
  position: absolute;
  right: 5%;
  top: 2px;
  z-index: 20;
}

.nav-pos-mobile-layer {
  position: absolute;
  left: 0px;
  top: 50px;
  min-width: 20%;
  text-align: right;
  /* background-color: #ece752; */
  /* background-color: #1cb7ce; */
  /* background-color: #ed7d31; */
  /* background-color: #ea5153; */
  background-color: var(--primary-color);

  border-radius: 5px;

  /* color: #fff; */
  text-transform: uppercase;
  padding: 10px;
  z-index: 20;
}

.navigation-ul {
  display: flex;
  justify-content: space-evenly;
  align-items: top;
  width: 38%;
  list-style: none;
  pointer-events: all;
  margin: 10px;
  margin-top: 24px;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #fff;
  padding: 2px;
}
.navigation-textcolor {
  color: #fff;
  color: #606060;
}
.navigation-textcolor-docked {
  color: #14143d;
}
.navigation-li {
  padding: 4px;
  font-size: 1.1em;
  color: #fff;
}
.navigation-li:hover {
  color: #fff;
}

.navigation-li-a {
  color: inherit;
  text-decoration: inherit;
}

.main-logo {
  position: absolute;
  left: 3%;
  top: 0px;
  background: #fff;
  padding: 6px;
  padding-left: 15px;
  padding-right: 12px;
  max-width: 500px;
  width: 70%;
  z-index: 5;
}
